<template>
  <v-container v-if="!loading">
    <v-card>
      <v-card-title>Add a Product</v-card-title>
      <v-divider class="ma-0"></v-divider>
      <v-card-text>
        <v-form v-model="valid">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Add a Product
                <small>Basic Details of a Product</small>
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step
                editable
                v-if="showVariation"
                :complete="e1 > 2"
                step="2"
              >
                Add a Variations of Product
                <small
                  >Add all the Variations of Product ( Size, Color, Price and
                  Image )</small
                >
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <basic-details
                  @category="category"
                  @subcategory="subcategory"
                  @updateStep="updateStep"
                  @haswarranty="hasWarranty"
                  @nowarranty="noWarranty"
                  @novariation="noVariation"
                  @hasvariation="hasVariation"
                  @pid="pid"
                  :colors="colors"
                  :sizes="sizes"
                  @variations="variations"
                  @submitAll="submit"
                ></basic-details>
              </v-stepper-content>

              <v-stepper-content step="2">
                <product-variation
                  @updateStep="updateStep"
                  @backStep="backStep"
                  :cakes="selectedcategory === 'cakes'"
                  :colors="colors"
                  :pid="id"
                  :sizes="sizes"
                  :variationson="variationsOn"
                  @multiVariationAdded="multiVariationAdded"
                ></product-variation>
                <v-btn
                  block
                  color="primary"
                  :loading="btnLoading"
                  :disabled="!submitFormFinally"
                  @click="submit"
                  >Submit</v-btn
                >
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <!-- 
            <v-stepper-step
              v-show="showWarranty"
              editable
              :complete="step > 2"
              :step="2"
            >
              Warranty Details
              <small>Add the warranty details of product</small>
            </v-stepper-step>
            <v-stepper-content v-show="showWarranty" :step="2">
              <warranty-details
                @updateStep="updateStep"
                @backStep="backStep"
              ></warranty-details>
            </v-stepper-content> -->

          <!-- <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 4"
              :step="4"
            >
              General Details
              <small
                >Add other Specific details ( Exists for few Categories )</small
              >
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="4">
              <general-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></general-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 5"
              :step="5"
            >
              Storage Details
              <small>Describe the Storage Features</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="5">
              <storage-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></storage-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 6"
              :step="6"
            >
              Processor Details
              <small>All Processor Features Stays here</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="6">
              <processor-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></processor-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 7"
              :step="7"
            >
              Camera Details
              <small>Add all camera features here</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="7">
              <camera-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></camera-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 8"
              :step="8"
            >
              Display Details
              <small>Display Features will stay here</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="8">
              <display-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></display-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 9"
              :step="9"
            >
              Power Supply Details
              <small>Add the power supply details</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="9">
              <powersupply-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></powersupply-details>
            </v-stepper-content>

            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 10"
              :step="10"
            >
              Connectivity Details
              <small>Add the Installation Details</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="10">
              <connectivity-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></connectivity-details>
            </v-stepper-content>
            <v-stepper-step
              v-if="restrictFields"
              editable
              :complete="step > 11"
              :step="11"
            >
              Other Details
              <small>Add the Other Details</small>
            </v-stepper-step>
            <v-stepper-content v-if="restrictFields" :step="11">
              <other-details
                @updateStep="updateStep"
                @backStep="backStep"
                :subcat="selectedsubcategory"
              ></other-details>
            </v-stepper-content> -->
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
  <circular-loaders v-else></circular-loaders>
</template>
<script>
import { mapState, mapActions } from "vuex";

import BasicDetails from "../../components/addproduct/BasicDetails.vue";
// import CameraDetails from "../../components/addproduct/details/CameraDetails.vue";
// import ConnectivityDetails from "../../components/addproduct/details/ConnectivityDetails.vue";
// import OtherDetails from "../../components/addproduct/details/OtherDetails.vue";
// import DisplayDetails from "../../components/addproduct/details/DisplayDetails.vue";
// import GeneralDetails from "../../components/addproduct/details/GeneralDetails.vue";
// import PowersupplyDetails from "../../components/addproduct/details/PowersupplyDetails.vue";
// import ProcessorDetails from "../../components/addproduct/details/ProcessorDetails.vue";
// import StorageDetails from "../../components/addproduct/details/StorageDetails.vue";
// import WarrantyDetails from "../../components/addproduct/details/WarrantyDetails.vue";
import ProductVariation from "../../components/addproduct/productvariation/ProductVariation.vue";
import CircularLoaders from "../../components/loaders/CircularLoaders.vue";

export default {
  components: {
    BasicDetails,
    ProductVariation,
    // GeneralDetails,
    // StorageDetails,
    // ProcessorDetails,
    // CameraDetails,
    // DisplayDetails,
    // PowersupplyDetails,
    // ConnectivityDetails,
    // WarrantyDetails,
    // OtherDetails,
    CircularLoaders,
  },
  data() {
    return {
      valid: false,
      search: "",
      e1: 1,
      selectedcategory: "",
      selectedsubcategory: "",
      showWarranty: false,
      showVariation: false,
      variationsOn: [],
      loading: false,
      btnLoading: false,
      id: "",
      submitFormFinally: false,
    };
  },
  computed: {
    ...mapState({
      colors: (state) => state.colors,
      sizes: (state) => state.sizes,
    }),
    restrictFields() {
      // electronic-devices-accessories
      if (this.selectedcategory === "electronic-devices-accessories") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["getUncompleteData"]),
    multiVariationAdded(val) {
      this.submitFormFinally = val;
    },
    category(cat) {
      this.selectedcategory = cat.slug;
    },
    subcategory(subcat) {
      this.selectedsubcategory = subcat.slug;
    },
    updateStep() {
      this.e1 = parseInt(this.e1) + 1;
    },
    backStep() {
      this.e1 = parseInt(this.e1) - 1;
    },
    hasWarranty() {
      this.showWarranty = true;
    },
    noWarranty() {
      this.showWarranty = false;
    },
    hasVariation() {
      this.showVariation = true;
    },
    noVariation() {
      this.showVariation = false;
    },
    pid(val) {
      this.id = val;
    },
    submit() {
      this.btnLoading = true;
      this.$store.dispatch("productadd", this.id).then((res) => {
        this.$store.commit("addProduct", res.data);
        this.$router.push("/products");
      });
    },
    variations(data) {
      data.color === true ? this.variationsOn.push("color") : "";
      data.type === true ? this.variationsOn.push("type") : "";
      data.size === true ? this.variationsOn.push("size") : "";
      data.weight === true ? this.variationsOn.push("weight") : "";
    },
  },
  mounted() {
    if (!this.$store.state.colors.length) {
      this.$store.dispatch("getColors");
    }
    if (!this.$store.state.sizes.length) {
      this.$store.dispatch("getSizes");
    }
  },
  // created() {
  //   this.loading = true;
  //   this.getUncompleteData().then(() => {
  //     this.loading = false;
  //   });
  // },
};
</script>
<style lang="scss">
.v-input__slot {
  border-radius: $border-radius !important;
}
.quillWrapper {
  .ql-snow.ql-toolbar,
  .ql-container {
    border-radius: $border-radius;
  }
}
</style>
